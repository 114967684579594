import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'

import PostCard from '../PostCard/PostCard'

import styles from './Posts.module.scss'

const Posts = ({ posts }) => {
  return (
    <div className={styles.root}>
      <div className="container">
        <h2>
          <FormattedMessage id="blog.headerTitle" />
        </h2>
        <p>
          {' '}
          <FormattedMessage id="blog.subheaderTitle" />
        </p>
        <div className={styles.postsContainer}>
          {posts.map(post => (
            <PostCard key={post.slug} post={post} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Posts
