import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'

import BlogComponent from '../components/Blog/Blog'
import Layout from '../components/layout'
import EarlyAcess from '../components/EarlyAcess/EarlyAcess'
import Footer from '../components/Footer/Footer'
import Helmet from '../components/helmet'

const Blog = ({ data, intl }) => {
  const posts = getBlogPosts(intl.locale, data)
  return (
    <Layout headerShadow={true}>
      <Helmet
        lang={intl.locale}
        pathName={`${intl.locale}/blog`}
        title={intl.formatMessage({ id: 'meta.blog.title' })}
        description={intl.formatMessage({ id: 'meta.blog.description' })}
      />
      <BlogComponent image={data.image} posts={posts} />
      <EarlyAcess />
      <Footer />
    </Layout>
  )
}

export default injectIntl(Blog)

function getBlogPosts(locale, cmsData) {
  const blogs = cmsData.posts.edges
    .filter(p => p.node.frontmatter.lng === locale)
    .map(({ node }) => {
      return {
        slug: node.frontmatter.path,
        image: node.frontmatter.image,
        title: node.frontmatter.title,
        leadText: node.frontmatter.leadText,
        date: node.frontmatter.date
      }
    })
    .sort((a, b) => {
      return new Date(b.date) - new Date(a.date)
    })
  return blogs
}

export const pageQuery = graphql`
  query BlogImage {
    image: file(relativePath: { eq: "blog-wallpaper.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    posts: allMarkdownRemark {
      edges {
        node {
          frontmatter {
            lng
            date
            leadText
            title
            path
            image {
              childImageSharp {
                fixed(width: 360, height: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
