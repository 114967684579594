import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styles from './Blog.module.scss'
import Posts from './Posts/Posts'

const Blog = ({ image, posts }) => {
  return (
    <div className={styles.root}>
      <BackgroundImage
        Tag="div"
        className={styles.banner}
        fluid={image.childImageSharp.fluid}
      ></BackgroundImage>
      <Posts posts={posts} />
    </div>
  )
}

export default Blog
