import React from 'react'
import styles from './PostCard.module.scss'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'

const PostCard = ({ post, intl }) => {
  let leadText = post.leadText
  if (leadText.length > 200) {
    leadText = `${leadText.substring(0, 160)}...`
  }
  return (
    <div className={styles.root}>
      <Link to={`/${intl.locale}/blog/${post.slug}/`}>
        <div className={styles.card}>
          <div className={styles.image}>
            <Img fixed={post.image.childImageSharp.fixed} />
          </div>
          <div className={styles.info}>
            <h3>{post?.title}</h3>
            <p>{leadText}</p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default injectIntl(PostCard)
